import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/dannyjones/homepage/src/components/MDXLayout/index.tsx";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
export const Header = styled.div`
  display: flex;
  align-items: center;
`;
export const ProfileImage = styled.div`
  border: 2px solid ${props => props.theme.colors.primary600};
  border-radius: 50%;
  margin-bottom: 1.45rem;
  height: 10rem;
  width: 10rem;
  flex-shrink: 0;
  overflow: hidden;
`;
export const Paragraph = styled.p`
  padding-left: ${props => props.theme.spacing4};
`;
export const pageQuery = graphql`
  query AboutImage3 {
    placeholderImage: file(relativePath: { eq: "about-profile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  Header,
  ProfileImage,
  Paragraph,
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <Header mdxType="Header">
  <ProfileImage mdxType="ProfileImage">
    <Img fluid={props.data.placeholderImage.childImageSharp.fluid} mdxType="Img" />
  </ProfileImage>
  <Paragraph mdxType="Paragraph">
    Passionate Ruby and JavaScript developer with a ten-year track record of
    delivering quality software for diverse businesses. Deep interest in open
    source software, the future of Ruby, and creating simple solutions to
    complex problems.
  </Paragraph>
    </Header>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      